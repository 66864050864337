// Site color settings & box sizes
@import 'constants';
@import 'navigation_responsive';
//----------------------------------
//-- Page Layout related settings --
//----------------------------------
html {
    font-size: 8px;
}

#pageHeader,
#headerContent {
    height: auto
}

// Adjust footer
.footerContent {
    width: 100%;
    .leftSideColumn {
        width: 100%;
    }
    .rightSideColumn {
        width: 100%;
    }
    .leftFooter {
        padding-left: 4px;
        img {
            padding-right: 4px;
        }
    }
}

#menuSwitcherBtnContainer {
    display: none;
}

// Replace the carousel with static image on narrow screen
.carousel {
    display: none;
}

.carouselNarrowScreen {
    display: block;
    img {
        display: block;
        height: 100%;
    }
}

.nav_wrapper {
    height: auto;
    margin: 0 auto;
    padding: 0;
}

#pageWrapper {
    padding: 60px 0 0 0;
    box-sizing: border-box;
}

#bodyContent {
    width: auto;
}

li#avatar,
li#lng,
#lngChooser,
.lang {
    height: 60px;
}

.title {
    width: 100%;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid #C1B29C;
    padding: 10px;
    box-sizing: border-box;
}

.triangle-topleft {
    display: none;
}

.padding {
    padding: 20px;
}

//------------------------------
//-- Carousel ------------------
//------------------------------
.carouselNarrowScreen {
    display: block;
    img {
        // position: absolute;
        display: block;
        /* make image flexible */
        max-width: 100%;
        height: auto;
        width: auto\9;
        /* IE8*/
    }
}

#prevBtn p,
#nextBtn p {
    font-size: 11em;
}

//------------------------------
//-- About page               --
//------------------------------
#siteLogo {
    margin-top: 17px;
}

//------------------------------
//-- Login page               --
//------------------------------
// CSI: no separate rules were necessary
//------------------------------
//-- Tree reg form            --
//------------------------------
#treeRecordingForm {
    fieldset {
        margin: 0;
        padding: 0;
        border: none;
    }
    // label { display: inline-flex; }
    input {
        right: 0;
    }
    // select {}
    legend {
        padding: 0;
        color: #000;
    }
    .mandatoryFieldsTree {
        width: 100%;
        height: auto;
        padding-bottom: 30px;
        border-bottom: 1px dotted #998F7F;
        input,
        select {
            float: none;
        }
        // input[type="file"] { float: none; }
    }
    .mandatoryLabel {
        // width: 150px;
        display: inline-flex;
    }
    .wide {
        margin: 0;
    }
    .treeCondition {
        width: 100%;
        height: auto;
        padding-bottom: 30px;
        border-bottom: 1px dotted #998F7F;
    }
    .humanImpact {
        width: 100%;
        height: auto;
        padding-bottom: 30px;
        border-bottom: 1px dotted #998F7F;
        margin-top: 0;
    }
    .treeLocation {
        width: 100%;
        height: auto;
        padding-bottom: 30px;
        border-bottom: 1px dotted #998F7F;
        margin-top: 0;
    }
    .treeImportance {
        width: 100%;
        height: auto;
        padding-bottom: 30px;
        border-bottom: 1px dotted #998F7F;
        margin-top: 0;
        textarea {
            height: 100px;
        }
    }
    .moreInfoSignBlind {
        display: none;
    }
    #id_location {
        margin: 0 0 0 103px;
        padding: 0;
    }
    .container {
        margin: 0;
    }
    .fieldsetOrderNo {
        font-weight: normal;
    }
    #treeFormBtn {
        margin-top: 0;
    }
    .moreInfoSign {
        background: url('/static/img/icon_question-mark_25x25.png') no-repeat center center;
        margin-right: 15px;
    }
}

//------------------------------
//-- Treelist page            --
//------------------------------
// CSI: rendben vannak a szabalyok, de meg nincs +oldva a responsive kepek

//------------------------------
//-- Tree details page        --
//------------------------------
#treeDetail {
    .signature {
        right: 0;
    }
    .treeDetails {
        margin-top: 30px;
    }
    .treeByVisitor {
        margin: 0 9px 7px 0;
    }
}

//------------------------------
//-- Map page                 --
//------------------------------
#headerContentMap {
    width: 100%;
    padding: 5px 35px 5px 15px;
    box-shadow: none;
}

#informationLinks {
    width: 100%;
}

#bodyContentMap {
    width: 100%;
}

#mapCanvas {
    width: 100%;
    min-height: 300px;
    margin-bottom: 0;
    border: none;
}

#bntFullScreen {
    float: none;
}

#informationLinks {
    height: 49px;
    border: none;
    box-shadow: none;
    #pac-input {
        position: absolute;
        width: auto;
        height: 28px;
        margin: 9px auto;
    }
    #btnFullScreen {
        width: auto;
        height: auto;
    }
    #btnEnterFullScreen {
        margin: 10px 15px;
    }
    .mapBtn {
        height: 48px;
    }
    .instructions {
        margin: 10px 15px;
    }
}

#btnExitFullScreen {
    position: fixed;
    // top: 65px;
    right: 5px;
}

// hide the footer on tablets
// .push {
//     display: none;
// }

// #pageFooter {
//     display: none;
// }

#showMoreLessControl {
    display: block;
}

.collapsed {
    height: 10vh;
    padding: 5px 15px 15px 15px;
    overflow: hidden;
}

.collapsed:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(to bottom, rgba(86, 86, 85, 0), rgba(86, 86, 85, 1) 90%);
    width: 100%;
    height: 3em;
}

// .treeDetail .gallery { padding: 0 10px; }
.treeDetail #arrow-left { width: 10%; }
.treeDetail #arrow-rigth { width: 10%; }
.treeDetail #gallery-container { width: 80% }

//------------------------------
//-- News page                --
//------------------------------
.newsInShort {
    padding: 0;
}

//------------------------------
//-- Stories page             --
//------------------------------
.story {
    padding: 5px;
}

//------------------------------
//-- Contact page             --
//------------------------------
div#leftCol {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

div#middleCol {
    width: 30%;
    padding: 0;
    font-size: 1em;
}

div#rightCol {
    width: 70%;
    padding: 0;
    margin: 25px 0 0 0;
    font-size: 1em;
    border: 1px solid #C1B29C;
    .plugin_googlemap,
    .google-map-container {
        height: 400px;
    }
}

//------------------------------
//-- User signup page         --
//------------------------------
.mandatoryFields,
.additionalUserInfo {
    width: 100%;
    display: block;
}
